import React, { ReactNode, useCallback, useMemo } from 'react'
import Dropdown, { Props as DropdownProps } from '../dropdown/dropdown'
import { Field, FieldConfig, useField, ErrorMessage } from 'formik'
import FormErrorMessage from '../form-error-message/form-error-message'
import InputLabel from '../input-label/input-label'
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Switch as MuiSwitch,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  SwitchProps as MuiSwitchProps,
  InputAdornment
} from '@material-ui/core'
import * as styles from './formik-fields.module.less'
import { useTranslation } from 'react-i18next'
import NumberFormat, { InputAttributes } from 'react-number-format'
import DatePicker, { Props as DatePickerProps } from '../date-picker/date-picker'
import { SpacingProps, useSpacing, Spacing } from 'src/hooks/spacing'
import cx from 'classnames'
import Spinner from '../spinner/spinner'

interface FieldWithLabelProps extends FieldConfig {
  label?: ReactNode
}

export interface DropdownFieldProps
  extends Omit<DropdownProps, 'name' | 'children' | 'value'>,
    FieldWithLabelProps {}

export const DropdownField: React.FC<DropdownFieldProps> = props => {
  const { onSelect, ...otherProps } = props
  const [{ value }, _, { setValue }] = useField(otherProps)
  return (
    <>
      {props.label && <Label>{props.label}</Label>}
      <Field
        {...props}
        fluid
        onSelect={o => {
          setValue(o.value)
          props.onSelect?.(o)
        }}
        selected={value}
        as={Dropdown}
      />
      <ErrorMessage name={props.name} component={FormErrorMessage} />
    </>
  )
}

interface Option {
  label: ReactNode
  value: string
}

interface CheckboxArrayFieldProps
  extends FieldWithLabelProps,
    Omit<CheckboxArrayProps, 'checked' | 'onCheckedChange'> {}

export const CheckboxArrayField: React.FC<CheckboxArrayFieldProps> = props => {
  const { options, label } = props
  const [{ value, ...fieldProps }, _, { setValue }] = useField<string[]>(props)

  const onCheckedChange = useCallback(
    (checked: { [key: string]: boolean }) => {
      setValue(options.map(o => o.value).filter(v => checked[v]))
    },
    [setValue, options]
  )

  const checked = useMemo(() => {
    const vals: { [key: string]: boolean } = {}
    options.forEach(({ value: v }) => (vals[v] = !!value?.includes(v)))
    return vals
  }, [value, options])

  return (
    <>
      {label && <Label>{label}</Label>}
      <CheckboxArray
        {...props}
        {...fieldProps}
        options={options}
        checked={checked}
        onCheckedChange={onCheckedChange}
      />
    </>
  )
}

interface CheckboxArrayProps {
  options: Option[]
  checked: { [key: string]: boolean }
  onCheckedChange: (checked: { [key: string]: boolean }) => void
  disabled?: boolean
}

export const CheckboxArray: React.FC<CheckboxArrayProps> = ({
  options,
  checked,
  onCheckedChange,
  disabled
}) => {
  return (
    <>
      {options.map(({ label, value }) => (
        <Checkbox
          disabled={disabled}
          key={value}
          label={label}
          checked={!!checked[value]}
          onChecked={isChecked => onCheckedChange({ ...checked, [value]: isChecked })}
        />
      ))}
    </>
  )
}

interface CheckboxProps {
  label?: ReactNode
  checked?: boolean
  onChecked?: (checked: boolean) => void
  disabled?: boolean
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChecked, disabled }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    onChecked?.(event.target.checked)
  }

  return (
    <FormControlLabel
      classes={{ label: styles.checkboxLabel }}
      label={label}
      onClick={event => event.stopPropagation()}
      control={
        <MuiCheckbox
          disabled={disabled}
          onChange={handleChange}
          checked={checked}
          color="default"
          className={styles.checkbox}
          classes={{ checked: styles.checkboxChecked }}
        />
      }
    />
  )
}

interface DateFieldProps extends FieldWithLabelProps {
  datePickerProps?: Omit<DatePickerProps, 'onChange'>
  onChange?: (date: Date | null) => void
}

export const DateField: React.FC<DateFieldProps> = ({
  label,
  datePickerProps,
  onChange,
  ...fieldConfig
}) => {
  const [{ value, ...fieldProps }, _, { setValue }] = useField(fieldConfig)
  return (
    <DatePicker
      withIcon
      {...fieldProps}
      selected={value ? new Date(value) : null}
      dateFormat="MMMM d, yyyy"
      onChange={date => {
        onChange?.(date)
        if (date) {
          setValue(date?.toISOString())
        }
      }}
      {...datePickerProps}
    />
  )
}

interface SwitchFieldProps extends FieldWithLabelProps {
  horizontal?: boolean
  disabled?: boolean
}

export const SwitchField: React.FC<SwitchFieldProps> = ({
  label,
  disabled,
  horizontal,
  ...props
}) => {
  const [{ value }, _, { setValue }] = useField(props)
  return (
    <Switch
      horizontal={horizontal}
      disabled={disabled}
      checked={value}
      label={label}
      onChange={e => setValue(e.target.checked)}
    />
  )
}

interface SwitchProps extends MuiSwitchProps {
  label?: ReactNode
  horizontal?: boolean
}

export const Switch: React.FC<SwitchProps> = props => {
  const { label, horizontal = true, ...switchProps } = props
  return (
    <div className={horizontal ? styles.switchHorizontal : styles.switchContainer}>
      {label && (
        <InputLabel
          spacing={{ margins: horizontal ? { md: 'vertical' } : { md: 'top', xs: 'bottom' } }}
        >
          {label}
        </InputLabel>
      )}
      <MuiSwitch
        {...switchProps}
        color={'default'}
        classes={{
          switchBase: styles.switchBase,
          root: styles.switchRoot,
          checked: styles.switchChecked,
          track: styles.switchTrack,
          thumb: styles.switchThumb
        }}
      />
    </div>
  )
}

interface RangeFieldItem {
  name: string
  label?: string
  textFieldProps?: MuiTextFieldProps & TextInputProps
}

interface RangeFieldProps extends Omit<FieldConfig, 'name'> {
  fields: [RangeFieldItem, RangeFieldItem]
  label?: ReactNode
  disabled?: boolean
}

export const RangeField: React.FC<RangeFieldProps> = ({ label, fields, disabled }) => {
  const min = useField(fields[0].name)
  const max = useField(fields[1].name)
  const rangeFields = [
    { ...fields[0], field: min },
    { ...fields[1], field: max }
  ]
  return (
    <>
      {label && <Label>{label}</Label>}
      <div className={styles.range}>
        {rangeFields.map(
          ({ name, label, textFieldProps, field: [{ value, ...fieldProps }, _, { setValue }] }) => {
            return (
              <div key={name} className={styles.rangeField}>
                <TextInput
                  {...fieldProps}
                  value={value ?? ''}
                  disabled={disabled}
                  onChange={({ target: { value } }) => setValue(value ? +value : null)}
                  inlineLabel={label}
                  type="number"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  {...(textFieldProps ?? {})}
                />
                <ErrorMessage component={FormErrorMessage} name={name} />
              </div>
            )
          }
        )}
      </div>
    </>
  )
}

export interface TextFieldProps
  extends Omit<MuiTextFieldProps, 'innerRef' | 'name' | 'children' | 'value' | 'type'>,
    FieldConfig,
    TextInputProps {
  flushTop?: boolean
}

export const TextField: React.FC<TextFieldProps> = ({ ...props }) => {
  return (
    <>
      <Field as={TextInput} {...props} variant="outlined" />
      <ErrorMessage component={FormErrorMessage} name={props.name} />
    </>
  )
}

interface TextInputProps extends SpacingProps {
  currencyInput?: boolean
  currencySign?: string
  percentageInput?: boolean
  inlineLabel?: string
  label?: ReactNode
  loading?: boolean
  flushTop?: boolean
}

export const TextInput: React.FC<MuiTextFieldProps & TextInputProps> = ({
  currencyInput,
  currencySign,
  percentageInput,
  label,
  inlineLabel,
  spacing,
  loading,
  flushTop,
  ...props
}) => {
  const { t } = useTranslation()
  const spacingClass = useSpacing(spacing)
  const additionalProps = useMemo(() => {
    if (currencyInput) {
      return {
        type: 'text',
        InputProps: {
          inputComponent: CurrencyFormat as any,
          startAdornment: (
            <InputAdornment position="start">
              {currencySign ? currencySign : t('currency sign')}
            </InputAdornment>
          )
        }
      }
    }
    if (percentageInput) {
      return {
        type: 'text',
        InputProps: {
          inputComponent: PercentageFormat as any,
          endAdornment: <InputAdornment position="end">{'%'}</InputAdornment>
        }
      }
    }
    if (loading) {
      return {
        InputProps: {
          endAdornment: (
            <div className={styles.textFieldLoading}>
              <Spinner fluid />
            </div>
          )
        }
      }
    }
    return {}
  }, [t, currencyInput, percentageInput, loading])

  return (
    <>
      {label && <Label flushTop={flushTop}>{label}</Label>}
      <MuiTextField
        {...props}
        {...additionalProps}
        className={cx(styles.textField, spacingClass)}
        label={inlineLabel}
      />
    </>
  )
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<InputAttributes> | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const CurrencyFormat: React.FC<NumberFormatCustomProps> = props => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => onChange({ target: { name: props.name, value: values.value } })}
      decimalScale={2}
      fixedDecimalScale
      thousandSeparator
      allowNegative={false}
      isNumericString
    />
  )
}

const PercentageFormat: React.FC<NumberFormatCustomProps> = props => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => onChange({ target: { name: props.name, value: values.value } })}
      decimalScale={1}
      allowNegative={false}
      isNumericString
      isAllowed={v => (v.floatValue ?? 0) <= 100}
    />
  )
}

export interface LabelProps {
  flushTop?: boolean
}

export const Label: React.FC<LabelProps> = ({ children, flushTop }) => {
  const margins: Spacing['margins'] = { sm: 'bottom' }
  if (!flushTop) {
    margins.lg = 'top'
  }
  return <InputLabel spacing={{ margins }}>{children}</InputLabel>
}

interface RangeContainerProps {
  label?: string
  errorName?: string
  noMargin?: boolean
}

export const RangeContainer: React.FC<RangeContainerProps> = ({
  children,
  label,
  errorName,
  noMargin
}) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <div
        className={cx(styles.rangeContainer, {
          [styles.rangeContainerMargin]: !errorName && !noMargin
        })}
      >
        {children}
      </div>
      {errorName && <ErrorMessage component={FormErrorMessage} name={errorName} />}
    </>
  )
}

interface TextAreaProps
  extends React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    SpacingProps {
  height?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
  resizable?: boolean
}

const TextArea: React.FC<TextAreaProps> = ({
  spacing,
  height = 'sm',
  resizable = false,
  ...props
}) => {
  const spacingClass = useSpacing(spacing)
  return (
    <textarea
      {...props}
      className={cx(styles.textArea, spacingClass, styles[height], {
        [styles.textAreaResizable]: !resizable
      })}
    />
  )
}

interface TextAreaField extends FieldConfig, Omit<TextAreaProps, 'name' | 'value' | 'children'> {}

export const TextAreaField: React.FC<TextAreaField> = props => {
  return (
    <>
      <Field as={TextArea} {...props} />
      <ErrorMessage component={FormErrorMessage} name={props.name} />
    </>
  )
}
