import React, { ComponentProps, ReactNode, useState } from 'react'
// https://github.com/Hacker0x01/react-datepicker/issues/879
import 'react-datepicker/dist/react-datepicker.css'
import ReactDP, { ReactDatePickerProps } from 'react-datepicker'
import { useSpacing, SpacingProps } from 'src/hooks/spacing'
import cx from 'classnames'
import * as styles from './date-picker.module.less'
import { useTranslation } from 'react-i18next'
import IconAdornment from '../icon-adornment/icon-adornment'
import Adornment from '../adornment/adornment'
import CalendarSVG from '../../images/icons/24px/calendar.svg'
import { FaArrowRight } from 'react-icons/fa'
import { formatDate } from 'src/utils/helper/membership'

export interface Props extends SpacingProps, ReactDatePickerProps {
  selected?: Date | null
  withIcon?: boolean
  field?: {
    value: Date
    name: string
  }
  classname?: string
  testId?: string
  customDateFormat?: string
}

const DatePicker: React.FC<Props> = React.forwardRef<any, Props>(
  ({ selected, classname, field, testId, spacing, withIcon, customDateFormat, ...props }, ref) => {
    const spacingClass = useSpacing(spacing)
    const { t } = useTranslation()

    const picker = (
      <ReactDP
        ref={ref}
        placeholderText={t('date placeholder')}
        customInput={<input data-test-id={testId} type="text" />}
        {...props}
        name={field ? field.name : props.name}
        className={cx(withIcon ? styles.datePickerWithIcon : styles.datePicker, classname)}
        wrapperClassName={withIcon ? '' : spacingClass}
        selected={field?.value ?? selected ?? null}
        dateFormat={customDateFormat ?? undefined}
      />
    )
    return withIcon ? pickerWithIcon(picker, spacingClass) : picker
  }
)

const pickerWithIcon = (picker: ReactNode, spacingClass?: string) => {
  return (
    <IconAdornment spacingClass={spacingClass}>
      <Adornment>
        <CalendarSVG className={styles.adornmentIcon} />
      </Adornment>
      {picker}
    </IconAdornment>
  )
}

interface JointProps extends Props {
  selectedStart?: Date
  selectedEnd?: Date
}

export const JointDatePicker: React.FC<JointProps> = ({
  selected,
  field,
  spacing,
  onChange,
  withIcon,
  ...props
}) => {
  // range demo
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [endDate, setEndDate] = useState<Date | undefined>(undefined)

  function handleDateChange(date) {
    // initial change start by setting the startDate
    if (!startDate && !endDate) {
      setStartDate(date)
      // startDate has been set, set the end date
    } else if (startDate && !endDate) {
      setEndDate(date)
    }

    //Prevent end date being before start date
    if (startDate && date && date < startDate) {
      setStartDate(date)
      setEndDate(undefined)
    }

    // user is choosing another range => set the start date
    // and set the endDate back to null
    if (startDate && endDate) {
      setStartDate(date)
      setEndDate(undefined)
    }
  }

  return (
    <div>
      <DatePicker
        onChange={(date, evt) => {
          onChange(date, evt)
          handleDateChange(date)
        }}
        selectsStart={true}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        withIcon={true}
        classname={styles.joint}
        customInput={
          <div className={styles.joint}>
            <p className={styles.innerJoint}>
              <span className={styles.startDate}>{startDate && formatDate(startDate, 'll')} </span>
              <FaArrowRight className={styles.arrowIcon} />
              <span className={styles.endDate}>{endDate && formatDate(endDate, 'll')}</span>
            </p>
          </div>
        }
      />
    </div>
  )
}

export default DatePicker
