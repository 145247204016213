import React from 'react'
import * as styles from './floating-box.module.less'

interface Props {
  side?: 'top' | 'bottom' | 'left' | 'right'
  visible?: boolean
}

const FloatingBox: React.FC<Props> = ({ children, side = 'bottom', visible = true }) => {
  return visible ? <div className={styles.floatingBox}>{children}</div> : null
}

export default FloatingBox
