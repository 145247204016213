import { Argument } from 'classnames'
import React from 'react'

export enum AppClient {
  USTA = 'USTA',
  ITA = 'ITA',
  TSI = 'TSI',
  DMOR = 'DMOR',
  SAAS = 'SAAS',
  TC = 'TC',
  LTA = 'LTA',
  GENERIC = 'GENERIC',
  STA = 'STA'
}

export type PropsFrom<TComponent> = TComponent extends React.FC<infer Props>
  ? Props
  : TComponent extends React.Component<infer Props>
  ? Props
  : TComponent extends React.ComponentType<infer Props>
  ? Props
  : never

export type ClassNamesMapping<T extends string> = Partial<Record<T, Argument>>

export interface ClassNamesProps<ClassKeys extends string> {
  classNames?: ClassNamesMapping<ClassKeys>
}

export interface ClassNameProps {
  className?: string
}

export type ValueOrGetValue<TValue, TArgs extends any[]> = TValue | ((...args: TArgs) => TValue)
