/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionTypeEnum {
  PlayerMerge = "PlayerMerge",
  SuspensionAdded = "SuspensionAdded",
  SuspensionCodeAdded = "SuspensionCodeAdded",
  SuspensionCodeDeleted = "SuspensionCodeDeleted",
  SuspensionCodeEdited = "SuspensionCodeEdited",
  SuspensionDeleted = "SuspensionDeleted",
  SuspensionEdited = "SuspensionEdited",
  SuspensionEmailDeleted = "SuspensionEmailDeleted",
  SuspensionEmailSent = "SuspensionEmailSent",
  SuspensionEmailUpdated = "SuspensionEmailUpdated",
  ViolationAdded = "ViolationAdded",
  ViolationDeleted = "ViolationDeleted",
  ViolationEdited = "ViolationEdited",
  WarningEmailDeleted = "WarningEmailDeleted",
  WarningEmailSent = "WarningEmailSent",
  WarningEmailUpdated = "WarningEmailUpdated",
}

export enum ActivityEventTypeEnum {
  CoachAssignedLevel = "CoachAssignedLevel",
  DoubleWins = "DoubleWins",
  JTT = "JTT",
  JuniorCircuit = "JuniorCircuit",
  ManualAdjustment = "ManualAdjustment",
  RoundRobin = "RoundRobin",
  SingleWins = "SingleWins",
  StarterPoints = "StarterPoints",
  TeamChallenge = "TeamChallenge",
  Unknown = "Unknown",
  YouthProgression = "YouthProgression",
}

export enum AddressTypeEnum {
  HOME = "HOME",
  MAIL = "MAIL",
  PRIMARY = "PRIMARY",
  RESIDENTIAL = "RESIDENTIAL",
  VENUE = "VENUE",
  WORK = "WORK",
}

export enum AgeRestrictionEnum {
  NA = "NA",
  y100 = "y100",
  y110 = "y110",
  y12 = "y12",
  y120 = "y120",
  y130 = "y130",
  y14 = "y14",
  y140 = "y140",
  y150 = "y150",
  y16 = "y16",
  y18 = "y18",
  y30 = "y30",
  y35 = "y35",
  y40 = "y40",
  y45 = "y45",
  y50 = "y50",
  y55 = "y55",
  y60 = "y60",
  y65 = "y65",
  y70 = "y70",
  y75 = "y75",
  y80 = "y80",
  y85 = "y85",
  y90 = "y90",
  y95 = "y95",
}

export enum BallColourEnum {
  Green = "Green",
  Orange = "Orange",
  Red = "Red",
  Yellow = "Yellow",
}

export enum CommonDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum CommonSearchOperatorsFieldsEnum {
  email = "email",
  externalId = "externalId",
  fullName = "fullName",
  personId = "personId",
  tennisId = "tennisId",
  userId = "userId",
}

export enum CountryCodeEnum {
  AFGHANISTAN = "AFGHANISTAN",
  ALAND_ISLANDS = "ALAND_ISLANDS",
  ALBANIA = "ALBANIA",
  ALGERIA = "ALGERIA",
  AMERICAN_SAMOA = "AMERICAN_SAMOA",
  ANDORRA = "ANDORRA",
  ANGOLA = "ANGOLA",
  ANGUILLA = "ANGUILLA",
  ANTARCTICA = "ANTARCTICA",
  ANTIGUA_AND_BARBUDA = "ANTIGUA_AND_BARBUDA",
  ANTILLES = "ANTILLES",
  ARGENTINA = "ARGENTINA",
  ARMENIA = "ARMENIA",
  ARUBA = "ARUBA",
  AUSTRALIA = "AUSTRALIA",
  AUSTRIA = "AUSTRIA",
  AZERBAIJAN = "AZERBAIJAN",
  BAHAMAS = "BAHAMAS",
  BAHRAIN = "BAHRAIN",
  BANGLADESH = "BANGLADESH",
  BARBADOS = "BARBADOS",
  BELARUS = "BELARUS",
  BELGIUM = "BELGIUM",
  BELIZE = "BELIZE",
  BENIN = "BENIN",
  BERMUDA = "BERMUDA",
  BHUTAN = "BHUTAN",
  BOLIVIA = "BOLIVIA",
  BOSNIA_AND_HERZEGOVINA = "BOSNIA_AND_HERZEGOVINA",
  BOTSWANA = "BOTSWANA",
  BOUVET_ISLAND = "BOUVET_ISLAND",
  BRAZIL = "BRAZIL",
  BRITISH_INDIAN_OCEAN_TERRITORY = "BRITISH_INDIAN_OCEAN_TERRITORY",
  BRUNEI_DARUSSALAM = "BRUNEI_DARUSSALAM",
  BULGARIA = "BULGARIA",
  BURKINA_FASO = "BURKINA_FASO",
  BURUNDI = "BURUNDI",
  CAMBODIA = "CAMBODIA",
  CAMEROON = "CAMEROON",
  CANADA = "CANADA",
  CAPE_VERDE = "CAPE_VERDE",
  CAYMAN_ISLANDS = "CAYMAN_ISLANDS",
  CENTRAL_AFRICAN_REPUBLIC = "CENTRAL_AFRICAN_REPUBLIC",
  CHAD = "CHAD",
  CHILE = "CHILE",
  CHINA = "CHINA",
  CHRISTMAS_ISLAND = "CHRISTMAS_ISLAND",
  COCOS_KEELING_ISLANDS = "COCOS_KEELING_ISLANDS",
  COLOMBIA = "COLOMBIA",
  COMOROS = "COMOROS",
  CONGO = "CONGO",
  CONGO_DEMOCRATIC_REPUBLIC = "CONGO_DEMOCRATIC_REPUBLIC",
  COOK_ISLANDS = "COOK_ISLANDS",
  COSTA_RICA = "COSTA_RICA",
  COTE_D_IVOIRE = "COTE_D_IVOIRE",
  CROATIA = "CROATIA",
  CUBA = "CUBA",
  CYPRUS = "CYPRUS",
  CZECHREPUBLIC = "CZECHREPUBLIC",
  DENMARK = "DENMARK",
  DJIBOUTI = "DJIBOUTI",
  DOMINICA = "DOMINICA",
  DOMINICAN_REPUBLIC = "DOMINICAN_REPUBLIC",
  ECUADOR = "ECUADOR",
  EGYPT = "EGYPT",
  EL_SALVADOR = "EL_SALVADOR",
  EQUATORIAL_GUINEA = "EQUATORIAL_GUINEA",
  ERITREA = "ERITREA",
  ESTONIA = "ESTONIA",
  ETHIOPIA = "ETHIOPIA",
  FALKLAND_ISLANDS = "FALKLAND_ISLANDS",
  FAROE_ISLANDS = "FAROE_ISLANDS",
  FIJI = "FIJI",
  FINLAND = "FINLAND",
  FRANCE = "FRANCE",
  FRENCH_GUIANA = "FRENCH_GUIANA",
  FRENCH_POLYNESIA = "FRENCH_POLYNESIA",
  FRENCH_SOUTHERN_TERRITORIES = "FRENCH_SOUTHERN_TERRITORIES",
  GABON = "GABON",
  GAMBIA = "GAMBIA",
  GEORGIA = "GEORGIA",
  GERMANY = "GERMANY",
  GHANA = "GHANA",
  GIBRALTAR = "GIBRALTAR",
  GREECE = "GREECE",
  GREENLAND = "GREENLAND",
  GRENADA = "GRENADA",
  GUADELOUPE = "GUADELOUPE",
  GUAM = "GUAM",
  GUATEMALA = "GUATEMALA",
  GUERNSEY = "GUERNSEY",
  GUINEA = "GUINEA",
  GUINEA_BISSAU = "GUINEA_BISSAU",
  GUYANA = "GUYANA",
  HAITI = "HAITI",
  HEARD_ISLAND_MCDONALD_ISLANDS = "HEARD_ISLAND_MCDONALD_ISLANDS",
  HOLY_SEE_VATICAN_CITY_STATE = "HOLY_SEE_VATICAN_CITY_STATE",
  HONDURAS = "HONDURAS",
  HONGKONG = "HONGKONG",
  HUNGARY = "HUNGARY",
  ICELAND = "ICELAND",
  INDIA = "INDIA",
  INDONESIA = "INDONESIA",
  IRAN = "IRAN",
  IRAQ = "IRAQ",
  IRELAND = "IRELAND",
  ISLE_OF_MAN = "ISLE_OF_MAN",
  ISRAEL = "ISRAEL",
  ITALY = "ITALY",
  JAMAICA = "JAMAICA",
  JAPAN = "JAPAN",
  JERSEY = "JERSEY",
  JORDAN = "JORDAN",
  KAZAKHSTAN = "KAZAKHSTAN",
  KENYA = "KENYA",
  KIRIBATI = "KIRIBATI",
  KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF = "KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF",
  KOREA_REPUBLIC_OF = "KOREA_REPUBLIC_OF",
  KUWAIT = "KUWAIT",
  KYRGYZSTAN = "KYRGYZSTAN",
  LAO_PEOPLES_DEMOCRATIC_REPUBLIC = "LAO_PEOPLES_DEMOCRATIC_REPUBLIC",
  LATVIA = "LATVIA",
  LEBANON = "LEBANON",
  LESOTHO = "LESOTHO",
  LIBERIA = "LIBERIA",
  LIBYAN_ARAB_JAMAHIRIYA = "LIBYAN_ARAB_JAMAHIRIYA",
  LIECHTENSTEIN = "LIECHTENSTEIN",
  LITHUANIA = "LITHUANIA",
  LUXEMBOURG = "LUXEMBOURG",
  MACAO = "MACAO",
  MADAGASCAR = "MADAGASCAR",
  MALAWI = "MALAWI",
  MALAYSIA = "MALAYSIA",
  MALDIVES = "MALDIVES",
  MALI = "MALI",
  MALTA = "MALTA",
  MARSHALL_ISLANDS = "MARSHALL_ISLANDS",
  MARTINIQUE = "MARTINIQUE",
  MAURITANIA = "MAURITANIA",
  MAURITIUS = "MAURITIUS",
  MAYOTTE = "MAYOTTE",
  MEXICO = "MEXICO",
  MICRONESIA = "MICRONESIA",
  MOLDOVA = "MOLDOVA",
  MONACO = "MONACO",
  MONGOLIA = "MONGOLIA",
  MONTENEGRO = "MONTENEGRO",
  MONTSERRAT = "MONTSERRAT",
  MOROCCO = "MOROCCO",
  MOZAMBIQUE = "MOZAMBIQUE",
  MYANMAR = "MYANMAR",
  NAMIBIA = "NAMIBIA",
  NAURU = "NAURU",
  NEPAL = "NEPAL",
  NETHERLANDS = "NETHERLANDS",
  NEW_CALEDONIA = "NEW_CALEDONIA",
  NEW_ZEALAND = "NEW_ZEALAND",
  NICARAGUA = "NICARAGUA",
  NIGER = "NIGER",
  NIGERIA = "NIGERIA",
  NIUE = "NIUE",
  NORFOLK_ISLAND = "NORFOLK_ISLAND",
  NORTHERN_MARIANA_ISLANDS = "NORTHERN_MARIANA_ISLANDS",
  NORWAY = "NORWAY",
  OMAN = "OMAN",
  PAKISTAN = "PAKISTAN",
  PALAU = "PALAU",
  PALESTINIAN_TERRITORY = "PALESTINIAN_TERRITORY",
  PANAMA = "PANAMA",
  PAPUA_NEW_GUINEA = "PAPUA_NEW_GUINEA",
  PARAGUAY = "PARAGUAY",
  PERU = "PERU",
  PHILIPPINES = "PHILIPPINES",
  PITCAIRN = "PITCAIRN",
  POLAND = "POLAND",
  PORTUGAL = "PORTUGAL",
  PUERTO_RICO = "PUERTO_RICO",
  QATAR = "QATAR",
  REPUBLIC_OF_NORTH_MACEDONIA = "REPUBLIC_OF_NORTH_MACEDONIA",
  REUNION = "REUNION",
  ROMANIA = "ROMANIA",
  RUSSIAN_FEDERATION = "RUSSIAN_FEDERATION",
  RWANDA = "RWANDA",
  SAINT_BARTHELEMY = "SAINT_BARTHELEMY",
  SAINT_HELENA = "SAINT_HELENA",
  SAINT_KITTS_AND_NEVIS = "SAINT_KITTS_AND_NEVIS",
  SAINT_LUCIA = "SAINT_LUCIA",
  SAINT_MARTIN = "SAINT_MARTIN",
  SAINT_PIERRE_AND_MIQUELON = "SAINT_PIERRE_AND_MIQUELON",
  SAINT_VINCENT_AND_GRENADINES = "SAINT_VINCENT_AND_GRENADINES",
  SAMOA = "SAMOA",
  SAN_MARINO = "SAN_MARINO",
  SAO_TOME_AND_PRINCIPE = "SAO_TOME_AND_PRINCIPE",
  SAUDI_ARABIA = "SAUDI_ARABIA",
  SENEGAL = "SENEGAL",
  SERBIA = "SERBIA",
  SEYCHELLES = "SEYCHELLES",
  SIERRA_LEONE = "SIERRA_LEONE",
  SINGAPORE = "SINGAPORE",
  SINT_MAARTEN_DUTCH_PART = "SINT_MAARTEN_DUTCH_PART",
  SLOVAKIA = "SLOVAKIA",
  SLOVENIA = "SLOVENIA",
  SOLOMON_ISLANDS = "SOLOMON_ISLANDS",
  SOMALIA = "SOMALIA",
  SOUTH_AFRICA = "SOUTH_AFRICA",
  SOUTH_GEORGIA_AND_SANDWICH_ISLAND = "SOUTH_GEORGIA_AND_SANDWICH_ISLAND",
  SOUTH_SUDAN = "SOUTH_SUDAN",
  SPAIN = "SPAIN",
  SRI_LANKA = "SRI_LANKA",
  SUDAN = "SUDAN",
  SURINAME = "SURINAME",
  SVALBARD_AND_JAN_MAYEN = "SVALBARD_AND_JAN_MAYEN",
  SWAZILAND = "SWAZILAND",
  SWEDEN = "SWEDEN",
  SWITZERLAND = "SWITZERLAND",
  SYRIAN_ARAB_REPUBLIC = "SYRIAN_ARAB_REPUBLIC",
  TAIWAN = "TAIWAN",
  TAJIKISTAN = "TAJIKISTAN",
  TANZANIA = "TANZANIA",
  THAILAND = "THAILAND",
  TIMOR_LESTE = "TIMOR_LESTE",
  TOGO = "TOGO",
  TOKELAU = "TOKELAU",
  TONGA = "TONGA",
  TRINIDAD_AND_TOBAGO = "TRINIDAD_AND_TOBAGO",
  TUNISIA = "TUNISIA",
  TURKEY = "TURKEY",
  TURKMENISTAN = "TURKMENISTAN",
  TURKS_AND_CAICOS_ISLANDS = "TURKS_AND_CAICOS_ISLANDS",
  TUVALU = "TUVALU",
  UGANDA = "UGANDA",
  UKRAINE = "UKRAINE",
  UNITED_ARAB_EMIRATES = "UNITED_ARAB_EMIRATES",
  UNITED_KINGDOM = "UNITED_KINGDOM",
  UNITED_STATES = "UNITED_STATES",
  UNITED_STATES_OUTLYING_ISLANDS = "UNITED_STATES_OUTLYING_ISLANDS",
  URUGUAY = "URUGUAY",
  UZBEKISTAN = "UZBEKISTAN",
  VANUATU = "VANUATU",
  VENEZUELA = "VENEZUELA",
  VIETNAM = "VIETNAM",
  VIRGIN_ISLANDS_BRITISH = "VIRGIN_ISLANDS_BRITISH",
  VIRGIN_ISLANDS_US = "VIRGIN_ISLANDS_US",
  WALLIS_AND_FUTUNA = "WALLIS_AND_FUTUNA",
  WESTERN_SAHARA = "WESTERN_SAHARA",
  YEMEN = "YEMEN",
  ZAMBIA = "ZAMBIA",
  ZIMBABWE = "ZIMBABWE",
}

export enum EventDrawTypeEnum {
  COMPASS = "COMPASS",
  CURTIS_CONSOLATION = "CURTIS_CONSOLATION",
  FEED_IN_CONSOLATION_QF = "FEED_IN_CONSOLATION_QF",
  FEED_IN_CONSOLATION_R16 = "FEED_IN_CONSOLATION_R16",
  FEED_IN_CONSOLATION_SF = "FEED_IN_CONSOLATION_SF",
  FIRST_MATCH_LOSERS_CONSOLATION = "FIRST_MATCH_LOSERS_CONSOLATION",
  FIRST_ROUND_LOSERS_CONSOLATION = "FIRST_ROUND_LOSERS_CONSOLATION",
  MODIFIED_FEED_IN = "MODIFIED_FEED_IN",
  QUALIFYING = "QUALIFYING",
  ROUND_ROBIN = "ROUND_ROBIN",
  ROUND_ROBIN_WITH_PLAYOFF = "ROUND_ROBIN_WITH_PLAYOFF",
  ROUND_ROBIN_WITH_PLAYOFF_4 = "ROUND_ROBIN_WITH_PLAYOFF_4",
  ROUND_ROBIN_WITH_PLAYOFF_8 = "ROUND_ROBIN_WITH_PLAYOFF_8",
  SINGLE_ELIMINATION = "SINGLE_ELIMINATION",
}

export enum EventLevelEnum {
  L1 = "L1",
  L2 = "L2",
  L3 = "L3",
  L4 = "L4",
  L5 = "L5",
  L6 = "L6",
  L7 = "L7",
}

export enum EventPlayerSortFieldsEnum {
  BONUS_POINTS = "BONUS_POINTS",
  DOUBLES_POINTS = "DOUBLES_POINTS",
  EVENT_NAME = "EVENT_NAME",
  LEVEL = "LEVEL",
  MATCH_FORMAT = "MATCH_FORMAT",
  PARTICIPATION_POINTS = "PARTICIPATION_POINTS",
  SINGLES_POINTS = "SINGLES_POINTS",
  TOTAL_POINTS = "TOTAL_POINTS",
  TOURNAMENT_NAME = "TOURNAMENT_NAME",
  TOURNAMENT_START = "TOURNAMENT_START",
}

export enum EventSortFieldsEnum {
  AGE_RESTRICTION = "AGE_RESTRICTION",
  GENDER = "GENDER",
  LEVEL = "LEVEL",
  MATCH_FORMAT = "MATCH_FORMAT",
  NTRP_RESTRICTION = "NTRP_RESTRICTION",
}

export enum FamilyCategoryEnum {
  FATHER_DAUGHTER = "FATHER_DAUGHTER",
  FATHER_SON = "FATHER_SON",
  GRANDFATHER_GRANDDAUGHTER = "GRANDFATHER_GRANDDAUGHTER",
  GRANDFATHER_GRANDSON = "GRANDFATHER_GRANDSON",
  GRANDMOTHER_GRANDDAUGHTER = "GRANDMOTHER_GRANDDAUGHTER",
  GRANDMOTHER_GRANDSON = "GRANDMOTHER_GRANDSON",
  HUSBAND_WIFE = "HUSBAND_WIFE",
  MOTHER_DAUGHTER = "MOTHER_DAUGHTER",
  MOTHER_SON = "MOTHER_SON",
  SAME_GENDER_FEMALE = "SAME_GENDER_FEMALE",
  SAME_GENDER_MALE = "SAME_GENDER_MALE",
}

export enum GroupGenderEnum {
  BOYS = "BOYS",
  COED = "COED",
  GIRLS = "GIRLS",
}

export enum MatchFormatEnum {
  COMBINED = "COMBINED",
  DOUBLES = "DOUBLES",
  SINGLES = "SINGLES",
}

export enum OrganisationClaimStatusEnum {
  CLAIMED = "CLAIMED",
  UNCLAIMED = "UNCLAIMED",
}

export enum OrganisationStatusEnum {
  ACTIVE = "ACTIVE",
  DUPLICATE = "DUPLICATE",
  INACTIVE = "INACTIVE",
}

export enum OrganisationTypeEnum {
  AREA = "AREA",
  CLUB = "CLUB",
  DISTRICT = "DISTRICT",
  NATIONAL_ASSOCIATION = "NATIONAL_ASSOCIATION",
  SCHOOL = "SCHOOL",
  SECTION = "SECTION",
}

export enum PersonIDEnum {
  ClubsparkID = "ClubsparkID",
  ID = "ID",
  UAID = "UAID",
}

export enum PersonStatusEnum {
  Active = "Active",
  Deceased = "Deceased",
  Deleted = "Deleted",
  Duplicate = "Duplicate",
  Inactive = "Inactive",
  None = "None",
  Unknown = "Unknown",
}

export enum PlayerCategory {
  Adult = "Adult",
  All = "All",
  Junior = "Junior",
  Wheelchair = "Wheelchair",
}

export enum PlayerCategoryEnum {
  Adult = "Adult",
  Junior = "Junior",
  Wheelchair = "Wheelchair",
}

export enum PlayerLevelEnum {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  L2_5 = "L2_5",
  L3_0 = "L3_0",
  L3_5 = "L3_5",
  L4_0 = "L4_0",
  L4_5 = "L4_5",
  L5_0 = "L5_0",
  Op = "Op",
  QUAD = "QUAD",
}

export enum PlayerTypeEnum {
  ADULT = "ADULT",
  FAMILY = "FAMILY",
  INVALID = "INVALID",
  JUNIOR = "JUNIOR",
  WHEELCHAIR = "WHEELCHAIR",
}

export enum PointsTypeEnum {
  BONUS = "BONUS",
  DOUBLES = "DOUBLES",
  PARTICIPATION = "PARTICIPATION",
  PPR = "PPR",
  PPW = "PPW",
  SINGLES = "SINGLES",
}

export enum RankListGenderEnum {
  C = "C",
  F = "F",
  M = "M",
  X = "X",
}

export enum RoleState {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
  REVOKED = "REVOKED",
}

export enum SafePlayStatus {
  APPROVED = "APPROVED",
  EXPIRED = "EXPIRED",
  NOTAPPROVED = "NOTAPPROVED",
  NOTSET = "NOTSET",
  NOTSTARTED = "NOTSTARTED",
  PENDING = "PENDING",
}

export enum SexEnum {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum SortDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SuspensionEmailTypeEnum {
  Suspension = "Suspension",
  ViolationDeleted = "ViolationDeleted",
  ViolationEdited = "ViolationEdited",
  ViolationIssued = "ViolationIssued",
  Warning = "Warning",
}

export enum SuspensionStatus {
  AtRisk = "AtRisk",
  AwaitingSuspension = "AwaitingSuspension",
  None = "None",
  Suspended = "Suspended",
}

export enum comp_LeagueStatus {
  APPROVED = "APPROVED",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  PLANNING = "PLANNING",
  PUBLISHED = "PUBLISHED",
  REGISTRATIONS_CLOSED = "REGISTRATIONS_CLOSED",
  REGISTRATIONS_OPEN = "REGISTRATIONS_OPEN",
}

export enum desk_ConfigType {
  RAW = "RAW",
  RESOLVED = "RESOLVED",
}

export interface ActivityEventFilterOptions {
  personIds?: (PersonIDInput | null)[] | null;
  ballColours?: (BallColourEnum | null)[] | null;
  refIds?: string[] | null;
  types?: ActivityEventTypeEnum[] | null;
  timestamp?: DateOperators | null;
  updatedAt?: DateOperators | null;
  createdAt?: DateOperators | null;
}

export interface ActivityManualAdjustmentInput {
  timestamp: any;
  ballColour: BallColourEnum;
  playPoints: number;
  singlesWinPoints: number;
  doublesWinPoints: number;
  reason?: string | null;
}

export interface ActivityTrackerEvaluationFilterOptions {
  personIds?: (PersonIDInput | null)[] | null;
  refreshPersons?: boolean | null;
}

export interface ActivityTrackerProfileFilterOptions {
  personIds?: (PersonIDInput | null)[] | null;
  ballColours?: (BallColourEnum | null)[] | null;
  updatedAt?: DateOperators | null;
  createdAt?: DateOperators | null;
}

/**
 * Address
 */
export interface AddressInput {
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressLine3?: string | null;
  addressName?: string | null;
  addressType?: AddressTypeEnum | null;
  city?: string | null;
  countryCode?: CountryCodeEnum | null;
  latitude?: string | null;
  longitude?: string | null;
  postalCode?: string | null;
  state?: string | null;
  timeZone?: string | null;
}

export interface AgeOperators {
  eq?: number | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  between?: NumberRange | null;
}

export interface AssociatedOrganisationOperators {
  organisationId: IdOperators;
  seasonId?: IdOperators | null;
}

export interface ColorInput {
  id: string;
}

export interface ColorsInput {
  main: ColorInput;
}

export interface DateOperators {
  eq?: any | null;
  before?: any | null;
  after?: any | null;
  between?: any | null;
}

export interface DateTimeOperators {
  eq?: any | null;
  before?: any | null;
  after?: any | null;
  between?: any | null;
}

export interface EventFilter {
  eventId?: string | null;
  collectionId?: string | null;
  level?: number | null;
  tournamentId?: string | null;
  division?: number | null;
  isTeamEvent?: boolean | null;
  playerType?: PlayerTypeEnum | null;
  gender?: RankListGenderEnum | null;
  playerLevel?: PlayerLevelEnum | null;
  ageRestriction?: AgeRestrictionEnum | null;
  matchFormat?: MatchFormatEnum | null;
  familyCategory?: FamilyCategoryEnum | null;
  playerId?: string | null;
}

export interface EventPlayerSort {
  field: EventPlayerSortFieldsEnum;
  direction: SortDirectionEnum;
}

export interface EventSort {
  field: EventSortFieldsEnum;
  direction: SortDirectionEnum;
}

/**
 * Extension
 */
export interface ExtensionInput {
  description?: string | null;
  name: string;
  value: any;
}

export interface FilterResourceInput {
  entityIds: string[];
  roles?: string[] | null;
  roleStates?: RoleState[] | null;
  includeChildren?: boolean | null;
  safePlayStatuses?: SafePlayStatus[] | null;
  entitySubTypes?: string[] | null;
  fullName?: string | null;
}

export interface GroupFilterOptionsInput {
  groupId?: StringOperators | null;
  groupName?: StringOperators | null;
  groupFullName?: StringOperators | null;
  parentOrganisation?: ParentOrganisationOperators | null;
  abbreviation?: StringOperators | null;
  groupGender?: GroupGenderEnum | null;
  groupType?: string | null;
  associatedOrganisation?: AssociatedOrganisationOperators | null;
}

export interface GroupOperators {
  id?: StringOperators | null;
  type?: StringOperators | null;
  seasonId?: StringOperators | null;
  role?: StringOperators | null;
}

export interface IdOperators {
  eq?: string | null;
}

/**
 * Filter List Players input model
 */
export interface ListPlayersFilter {
  search?: string | null;
  status?: SuspensionStatus | null;
  category?: PlayerCategory | null;
}

export interface NumberRange {
  start: number;
  end: number;
}

export interface OrganisationClaimStatusEnumOperators {
  eq?: OrganisationClaimStatusEnum | null;
  ne?: OrganisationClaimStatusEnum | null;
}

export interface OrganisationFilterOptionsInput {
  search?: string | null;
  organisationName?: StringOperators | null;
  postalCode?: StringOperators | null;
  organisationType?: OrganisationTypeEnumOperators | null;
  parentOrganisationId?: string | null;
  claimStatus?: OrganisationClaimStatusEnumOperators | null;
  status?: OrganisationStatusEnumOperators | null;
}

/**
 * Organisation input 
 */
export interface OrganisationInput {
  code?: string | null;
  sectionOrganisationId?: string | null;
  addresses?: AddressInput[] | null;
  organisationAbbreviation?: string | null;
  organisationId?: string | null;
  organisationName?: string | null;
  organisationType?: OrganisationTypeEnum | null;
  parentOrganisationId?: string | null;
  extensions?: ExtensionInput[] | null;
  additionalFields?: any | null;
  externalId?: string | null;
  profileImage?: ProfileImageInput | null;
  slug?: string | null;
  colors?: ColorsInput | null;
  status?: OrganisationStatusEnum | null;
}

export interface OrganisationStatusEnumOperators {
  in?: OrganisationStatusEnum[] | null;
  nin?: OrganisationStatusEnum[] | null;
}

export interface OrganisationTypeEnumOperators {
  eq?: OrganisationTypeEnum | null;
  ne?: OrganisationTypeEnum | null;
}

export interface OrganisationUpdateInput {
  code?: string | null;
  sectionOrganisationId?: string | null;
  addresses?: AddressInput[] | null;
  organisationAbbreviation?: string | null;
  organisationId?: string | null;
  organisationName?: string | null;
  organisationType?: OrganisationTypeEnum | null;
  parentOrganisationId?: string | null;
  extensions?: ExtensionInput[] | null;
  additionalFields?: any | null;
  externalId?: string | null;
  profileImage?: ProfileImageInput | null;
  slug?: string | null;
  colors?: ColorsInput | null;
  status?: OrganisationStatusEnum | null;
}

export interface PaginationArgs {
  limit: number;
  skip: number;
}

export interface ParentOrganisationOperators {
  organisationId?: string | null;
  name?: StringOperators | null;
  code?: StringOperators | null;
  sectionCode?: StringOperators | null;
  sectionOrganisationId?: StringOperators | null;
}

export interface PersonFilterOptionsInput {
  search?: SearchOperators | null;
  age?: AgeOperators | null;
  updatedAt?: DateTimeOperators | null;
  createdAt?: DateTimeOperators | null;
  nationalityCode?: StringOperators | null;
  district?: StringOperators | null;
  section?: StringOperators | null;
  sex?: SexEnum | null;
  category?: PlayerCategoryEnum | null;
  providerId?: string | null;
  state?: string | null;
  status?: PersonStatusEnum | null;
  group?: GroupOperators | null;
}

export interface PersonIDInput {
  identifier: string;
  type: PersonIDEnum;
}

export interface PlayerPlayHistoryFilter {
  playerId: string;
  level?: EventLevelEnum | null;
  drawType?: EventDrawTypeEnum | null;
  matchFormat?: MatchFormatEnum | null;
  editStatus?: boolean | null;
}

/**
 * ProfileImage
 */
export interface ProfileImageInput {
  small?: string | null;
  medium?: string | null;
  large?: string | null;
}

export interface SearchOperators {
  normalized?: string | null;
  value?: string | null;
  fields?: CommonSearchOperatorsFieldsEnum[] | null;
}

export interface SortOrder {
  field: string;
  direction: CommonDirectionEnum;
}

export interface StringOperators {
  eq?: string | null;
  contains?: string | null;
}

/**
 * create `SuspensionCode` input model
 */
export interface SuspensionCodeCreateInput {
  code: string;
  longDescription: string;
  shortDescription: string;
  points: number;
}

/**
 * update `SuspensionCode` input model
 */
export interface SuspensionCodeUpdateInput {
  code?: string | null;
  longDescription?: string | null;
  shortDescription?: string | null;
  points?: number | null;
}

/**
 * create `Suspension` input model
 */
export interface SuspensionCreateInput {
  uaid: string;
  startDate: any;
  endDate: any;
  comment?: string | null;
  violations?: string[] | null;
}

/**
 * create `SuspensionEmail` input model
 */
export interface SuspensionEmailCreateInput {
  uaid: string;
  comment?: string | null;
  type: SuspensionEmailTypeEnum;
  sentDate?: any | null;
}

/**
 * update `SuspensionEmail` input model
 */
export interface SuspensionEmailUpdateInput {
  uaid?: string | null;
  comment?: string | null;
  type?: SuspensionEmailTypeEnum | null;
  sentDate: any;
}

/**
 * update `Suspension` input model
 */
export interface SuspensionUpdateInput {
  uaid?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  comment?: string | null;
}

export interface comp_DateFilter {
  gte?: string | null;
  lt?: string | null;
}

export interface comp_LeaguesFilter {
  parentOrganisationId?: string | null;
  startDate?: comp_DateFilter | null;
  endDate?: comp_DateFilter | null;
}

export interface comp_PaginationInput {
  limit: number;
  skip: number;
}

export interface desk_OrgConfigInput {
  tieFormatTemplates?: any[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
