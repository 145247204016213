/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AddressTypeEnum {
  BILLING = "BILLING",
  MAILING = "MAILING",
  PHYSICAL = "PHYSICAL",
}

export enum MemberStateEnum {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  LAPSED = "LAPSED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  UNKNOWN = "UNKNOWN",
}

export enum MemberTypeEnum {
  INDIVIDUAL = "INDIVIDUAL",
  ORGANISATION = "ORGANISATION",
}

export enum MembershipStateEnum {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  DIRECT_LINK = "DIRECT_LINK",
  HIDDEN = "HIDDEN",
  UNKNOWN = "UNKNOWN",
}

export enum MembershipTypeEnum {
  INDIVIDUAL = "INDIVIDUAL",
  ORGANISATION = "ORGANISATION",
}

export enum ReportState {
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
}

export enum SortByFieldEnum {
  CODE = "CODE",
  END = "END",
  MEMBERS = "MEMBERS",
  NAME = "NAME",
  SOLD = "SOLD",
  START = "START",
  STATUS = "STATUS",
  TYPE = "TYPE",
}

export enum SortDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
  NONE = "NONE",
}

export enum TransactionSuccessEnum {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  SUCCESS = "SUCCESS",
}

export interface AddressInput {
  country?: string | null;
  countryCode?: string | null;
  state?: string | null;
  city?: string | null;
  zipCode?: string | null;
  street?: string | null;
  houseNumber?: string | null;
}

export interface PaginationInput {
  page?: number | null;
  entriesPerPage?: number | null;
}

export interface SortingInput {
  sortBy?: SortByFieldEnum | null;
  sortDirection?: SortDirectionEnum | null;
}

export enum MembershipCodeEnum {
  ASCPRO = "ASCPRO",
  COEM = "COEM",
  CONF = "CONF",
  CORP = "CORP",
  CPM = "CPM",
  PLA = "PLA",
  SUM = "SUM",
  USTASEC = "USTASEC"
}

//==============================================================
// END Enums and Input Objects
//==============================================================
