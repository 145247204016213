import React, { useState, useCallback, useEffect } from 'react'
import { useLocation } from '@reach/router'
import * as styles from './sidebar.module.less'
import { Link } from 'gatsby'
import { Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Icon, { IconProps } from 'src/components/icon/icon'
import { getClientConfig } from 'src/config/config'
import Backdrop from '../backdrop/backdrop'

interface NavState {
  hideFlyoutOnMount?: boolean
}

export interface NavLink {
  id: string
  to: string
  iconName?: IconProps['name']
  label: string
  subLinks?: NavLink[]
  hide?: boolean
}

interface Props {
  links: NavLink[]
}

const Sidebar: React.FC<Props> = ({ links }) => {
  const [activeSubMenu, setActiveSubMenu] = useState<NavLink>()
  const openSubMenu = useCallback(
    (l: NavLink) => setActiveSubMenu(activeSubMenu?.id === l.id ? undefined : l),
    [activeSubMenu, activeSubMenu]
  )
  const { state: navState } = useLocation() as { state: NavState }
  return (
    <>
      <aside className={styles.sidebar}>
        <img
          className={styles.logo}
          src={require(`src/images/${getClientConfig().logoSmallFilename}`).default}
        />
        <nav className={styles.nav}>
          {links.map(l => {
            return (
              <NavItem
                {...l}
                key={l.id}
                onClick={l.subLinks && (() => openSubMenu(l))}
                state={{ hideFlyoutOnMount: !!activeSubMenu }}
              />
            )
          })}
        </nav>
      </aside>
      <SubNavFlyout
        // hideOnMount={navState?.hideFlyoutOnMount}
        title={activeSubMenu?.label}
        links={activeSubMenu?.subLinks}
        open={!!activeSubMenu?.subLinks}
        onClose={() => setActiveSubMenu(undefined)}
      />
    </>
  )
}

interface NavItemProps {
  to: string
  iconName?: IconProps['name']
  label?: string
  id: string
  isSubNav?: boolean
  onClick?: () => any
  state?: NavState
  hide?: boolean
}

const NavItem: React.FC<NavItemProps> = ({
  label,
  iconName,
  id,
  isSubNav,
  onClick,
  to,
  state,
  hide
}) => {
  const onLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (onClick) {
        e.preventDefault()
        onClick()
      }
    },
    [onClick]
  )

  if (hide) return null

  const Item = (
    <Link
      onClick={onLinkClick}
      id={id}
      className={isSubNav ? styles.labelLink : styles.iconLink}
      activeClassName={styles.activeLink}
      partiallyActive={!isSubNav && to !== '/'}
      to={to}
      state={state}
    >
      {iconName && <Icon name={iconName} className={styles.linkIconSvg} />}
      {isSubNav && label}
    </Link>
  )
  return isSubNav || !label ? Item : withTooltip(label)(Item)
}

const withTooltip = (label: string) => (c: JSX.Element) => (
  <Tooltip title={label} placement="right" classes={{ tooltip: styles.tooltip }}>
    {c}
  </Tooltip>
)

interface SubNavFlyoutProps {
  links?: NavLink[]
  title?: string
  onClose?: () => any
  open?: boolean
  hideOnMount?: boolean
}

const SubNavFlyout: React.FC<SubNavFlyoutProps> = ({
  links,
  onClose,
  open,
  hideOnMount,
  title
}) => {
  const [initialOpen, setInitialOpen] = useState(open || hideOnMount)
  const { t } = useTranslation()
  useEffect(() => {
    if (hideOnMount) requestAnimationFrame(() => setInitialOpen(false))
  }, [hideOnMount, setInitialOpen])
  const isOpen = open || initialOpen
  return (
    <>
      <aside className={isOpen ? styles.flyout : styles.flyoutGone}>
        <button className={styles.closeFlyout} onClick={() => onClose?.()}>
          <Icon name="md-close" />
        </button>
        <nav className={styles.subnav}>
          <h3 className={styles.subnavTitle}>{title}</h3>
          {links?.map(l => (
            <NavItem key={l.id} {...l} state={{ hideFlyoutOnMount: true }} isSubNav />
          ))}
        </nav>
      </aside>
      <Backdrop open={!!isOpen} onClick={onClose} />
    </>
  )
}

export default Sidebar
