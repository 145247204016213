import React from 'react'
import * as styles from './adornment.module.less'
import cx from 'classnames'

interface Props {
  children: any
  input?: boolean
}

const Adornment: React.FC<Props> = ({ children, input }) => {
  return (
    <div className={input ? cx(styles.container, styles.altContainer) : styles.container}>
      {children}
    </div>
  )
}

export default Adornment
