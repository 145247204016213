import React, { HTMLProps } from 'react'
import { useSpacing, SpacingProps } from 'src/hooks/spacing'
import * as styles from './input-label.module.less'
import cx from 'classnames'

export interface InputProps extends Omit<HTMLProps<HTMLLabelElement>, 'size'>, SpacingProps {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  className?: string
}

const sizeClassMap = { xs: 'extraSmall', sm: 'small', md: 'medium', lg: 'large' }

const InputLabel: React.FC<InputProps> = ({
  size = 'sm',
  spacing,
  children,
  className,
  ...props
}) => {
  const spacingClass = useSpacing(spacing)
  return (
    <label {...props} className={cx(styles.inputLabel, spacingClass, className)}>
      {children}
    </label>
  )
}

export default InputLabel
