import React, { useMemo } from 'react'
import { useScopeAccess, useOrgLevel, StaffScope, useHasScope, useOrgType } from 'src/utils/auth'
import ROUTES from 'src/utils/routes'
import Sidebar, { NavLink } from '../sidebar/sidebar'
import { useTranslation } from 'react-i18next'
import { getClientConfig, getEnvConfig } from 'src/config/config'
import { AppClient } from 'src/types'
import { isClientOneOf } from 'src/utils/helper/clients'
import { OrganisationTypeEnum } from '../../graphql-types/globalUstaTypes'

const SidebarNavigation: React.FC = () => {
  const { t } = useTranslation()

  const { isNational } = useOrgLevel()
  const rankingsAccess = useHasScope([StaffScope.RANKINGS])
  const suspensionsAccess =
    !getClientConfig().disableModules?.includes('suspension') &&
    useHasScope([StaffScope.SUSPENSIONS])
  const mergePlayersAccess = !getClientConfig()?.disableModules?.includes('ita-merge-players')

  const isIta = isClientOneOf(AppClient.ITA)
  const isUsta = isClientOneOf(AppClient.USTA)
  const orgType = useOrgType()

  const rankingsAccessUsta = rankingsAccess && isUsta

  const { getAccessInfo } = useScopeAccess()

  const navLinks = useMemo<NavLink[]>(() => {
    const links: NavLink[] = [
      { id: 'college', to: '/', label: t('dashboard'), iconName: 'dashboard' },
      {
        id: 'memberships',
        to: ROUTES.MEMBERSHIPS,
        label: t('membership'),
        iconName: 'membership'
      },
      {
        id: 'lta-memberships',
        to: ROUTES.LTA_MEMBERSHIPS,
        label: t('memberships'),
        iconName: 'membership'
      },
      {
        id: 'tournaments',
        to: ROUTES.TOURNAMENTS,
        iconName: 'tournaments',
        label: t('tournaments'),
        subLinks: [
          { id: 'your-tournaments', to: ROUTES.TOURNAMENTS, label: t('your tournaments') },
          {
            id: 'tournament-groups',
            to: ROUTES.TOURNAMENT_GROUPS,
            label: t('tournament groups'),
            hide: !isNational
          },
          {
            id: 'tournament-directors',
            to: ROUTES.TOURNAMENT_DIRECTORS,
            label: t('tournament directors')
          },
          { id: 'level-config', to: ROUTES.LEVEL_CONFIG, label: t('level configuration') },
          { id: 'payout-reports', to: ROUTES.PAYOUTS, label: t('payout reports') },
          {
            id: 'financial-reports',
            to: ROUTES.FINANCIAL_REPORTS,
            label: t('financial reports'),
            hide: getEnvConfig().FINANCIAL_REPORTS_ENABLED !== 'enabled' || isNational
          },
          {
            id: 'tournaments-report',
            to: ROUTES.TOURNAMENTS_REPORT,
            label: t('tournaments report'),
            hide: getEnvConfig().FINANCIAL_REPORTS_ENABLED !== 'enabled' || isNational
          },
          { id: 'tournament-settings', to: ROUTES.TOURNAMENT_SETTINGS, label: t('settings') }
        ]
      },
      { id: 'staff', to: ROUTES.STAFF, label: t('staff users'), iconName: 'users' },
      { id: 'reports', to: ROUTES.REPORTS, label: t('reports'), iconName: 'reports' },
      {
        id: 'rankings',
        to: ROUTES.RANKINGS,
        label: t('rankings'),
        iconName: 'ranking',
        hide: !isIta
      },
      {
        id: 'rankings-usta',
        to: ROUTES.RANKINGS,
        label: t('rankings'),
        iconName: 'ranking',
        subLinks: [
          {
            id: 'manage-rankings',
            to: ROUTES.RANKINGS,
            label: t('manage rankings')
          },
          {
            id: 'player points',
            to: ROUTES.PLAYER_POINTS,
            label: t('player points'),
            hide: !isNational
          },
          {
            id: 'reports',
            to: ROUTES.RANKING_REPORTS,
            label: t('reports'),
            hide: !isNational
          }
        ],
        hide: !rankingsAccessUsta
      },
      {
        id: 'players',
        to: ROUTES.PLAYERS,
        label: t('players'),
        iconName: 'players',
        subLinks:
          suspensionsAccess || mergePlayersAccess
            ? [
                {
                  id: 'players',
                  to: ROUTES.PLAYERS,
                  label: t('players')
                },
                ...(suspensionsAccess
                  ? [
                      {
                        id: 'suspension-points',
                        to: ROUTES.SUSPENSION_POINTS,
                        label: t('active violations')
                      },
                      {
                        id: 'suspension-codes',
                        to: ROUTES.SUSPENSION_CODES,
                        label: t('suspension codes'),
                        hide: !isNational
                      }
                    ]
                  : []),
                ...(mergePlayersAccess
                  ? [
                      {
                        id: 'ita-merge-players',
                        to: ROUTES.MERGE_PLAYERS,
                        label: t('merge players')
                      }
                    ]
                  : [])
              ]
            : undefined
      },
      {
        id: 'schools',
        to: ROUTES.SCHOOLS,
        label: t('schools'),
        iconName: 'schools'
      },
      {
        id: 'organisations',
        to: ROUTES.MANAGE_SCHOOLS,
        label: t('organizations'),
        iconName: 'schools'
        // subLinks: [
        //   {
        //     id: 'manage-schools',
        //     to: ROUTES.MANAGE_SCHOOLS,
        //     label: t('manage schools')
        //   },
        //   {
        //     id: 'manage-organisations',
        //     to: ROUTES.MANAGE_ORGANIZATIONS,
        //     label: t('manage organisations', {
        //       clientOrgsTitle: organisationsTitleByClient
        //     })
        //   }
        // ]
      },
      {
        id: 'areas',
        to: ROUTES.AREAS,
        iconName: 'areas',
        label: t('areas'),
        hide: orgType !== OrganisationTypeEnum.NATIONAL_ASSOCIATION,
        subLinks: [
          { id: 'manage-areas', to: ROUTES.AREAS, label: t('manage areas') },
          {
            id: 'manage-coordinators',
            to: ROUTES.AREAS_COORDINATORS,
            label: t('manage coordinators')
          }
        ]
      },
      {
        id: 'leagues',
        to: ROUTES.LEAGUES,
        iconName: 'leagues',
        label: t('leagues'),
        subLinks: [
          { id: 'leagues', to: ROUTES.LEAGUES, label: t('leagues') },
          { id: 'leagues-coordinators', to: ROUTES.LEAGUES_COORDINATORS, label: t('coordinators') }
        ]
      }
    ]
    return links.map((l: NavLink) => {
      if (
        getClientConfig().disableModules?.some(m => l.id === m) ||
        !getAccessInfo(l.to).hasAccess
      ) {
        return { ...l, hide: true }
      }
      return {
        ...l,
        subLinks: l.subLinks?.map(sl => ({
          ...sl,
          hide: sl.hide || !getAccessInfo(sl.to).hasAccess
        }))
      }
    })
  }, [
    t,
    isNational,
    isIta,
    rankingsAccessUsta,
    suspensionsAccess,
    mergePlayersAccess,
    orgType,
    getAccessInfo
  ])

  return <Sidebar links={navLinks} />
}

export default SidebarNavigation
