import { useApolloClient, useQuery } from '@apollo/client'
import { navigate } from 'gatsby'
import React, { ButtonHTMLAttributes, useCallback, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FaChevronDown, FaSearch } from 'react-icons/fa'
import { IconType } from 'react-icons/lib/cjs'
import { GET_SELECTED_FACILITY, setSelectedFacility } from 'src/apollo/local-state'
import { TextInput } from 'src/components/formik-fields/formik-fields'
import { getClientConfig } from 'src/config/config'
import { getUsername, logOut, resetSession, userIsGlobalAdmin } from 'src/utils/auth'
import { searchSectionDistrict } from 'src/utils/classic-api'
import { retrieveUserVenues, storeDefaultFacility } from 'src/utils/storage/local-storage'
import Backdrop from '../backdrop/backdrop'
import Button from '../button/button'
import FloatingBox from '../floating-box/floating-box'
import * as styles from './header.module.less'

const Header: React.FC = () => {
  const [searchExapnded, setSearchExpanded] = useState(false)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const client = useApolloClient()

  const { t } = useTranslation()
  return (
    <>
      <header className={styles.header}>
        {/* <IconButton icon={FaBars} onClick={tgl} /> */}
        <span className={styles.contentLeft}>
          {!getClientConfig().hideFacilitySelect && <FacilitySelect />}
          <hr className={styles.divider} />
          {/* <SearchButton /> */}
        </span>
        {/* <IconButton icon={FaExpandArrowsAlt} />
      <IconButton icon={FaEnvelope} />
      <IconButton icon={FaBell} />
      <IconButton icon={FaQuestionCircle} /> */}
        <FloatingBox visible={showUserMenu}>
          <Button onClick={() => logOut(client)}>{t('log out')}</Button>
        </FloatingBox>
        <button className={styles.accountDropdown} onClick={() => setShowUserMenu(!showUserMenu)}>
          <Avatar />
          <FaChevronDown />
        </button>
      </header>
      <Backdrop open={showUserMenu} onClick={() => setShowUserMenu(false)} />
    </>
  )
}

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconType
  size?: number
}

const IconButton: React.FC<IconButtonProps> = ({ icon: Icon, size = 22, ...props }) => {
  return (
    <button {...props} type="button" className={styles.iconButton}>
      <Icon size={size} />
    </button>
  )
}

const SearchButton: React.FC = () => {
  return (
    <button className={styles.searchButton}>
      <FaSearch />
      <span className={styles.searchLabel}>Search</span>
    </button>
  )
}

const Avatar: React.FC = () => {
  const username = useMemo(getUsername, [])
  return <span className={styles.avatarInitials}>{username[0]?.toUpperCase() ?? '?'}</span>
}

const FacilitySelect: React.FC = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const toggle = useCallback(() => setOpen(!open), [open, setOpen])
  const venues = useMemo(() => retrieveUserVenues(), [])
  const client = useApolloClient()
  const { data: facilityData } = useQuery(GET_SELECTED_FACILITY)

  useEffect(() => {
    // the client assumes an org will always be available, if for some
    // reason it isn't set (e.g. cleared from storage) we return the user
    // back to the login screen where they will have to sign in again.
    if (!facilityData?.selectedFacility) {
      resetSession(client)
    }
  }, [facilityData])

  const facilityName = useMemo(() => {
    const facility = facilityData && JSON.parse(facilityData.selectedFacility)
    return facility?.Name
  }, [facilityData])

  const selectFacility = useCallback(
    async (facility: any) => {
      await client.clearStore()
      setSelectedFacility(facility, client)
      storeDefaultFacility(facility)
      toggle()
      navigate('/')
    },
    [toggle, client]
  )

  const isGlobalAdmin = useMemo(() => userIsGlobalAdmin(), [])

  // For single venues we do not display drop down
  const unselectable = useMemo(() => {
    return !isGlobalAdmin && venues?.length === 1
  }, [venues, isGlobalAdmin])

  return (
    <>
      <div className={styles.selectFacility}>
        {!unselectable && (
          <button className={styles.facilityButton} onClick={toggle}>
            {facilityName}
            <FaChevronDown className={styles.facilityChevron} />
          </button>
        )}
        {unselectable && <span className={styles.facilityButton}>{facilityName}</span>}
        <div className={open ? styles.facilityDropdown : styles.facilityDropdownHidden}>
          {venues && venues.length > 0 && (
            <>
              <FacilitySelectHeading title={t('facility select')} />
              <ul className={styles.facilityList}>
                {venues?.map(r => (
                  <FacilityItem key={r.VenueID} facility={r} selectFacility={selectFacility} />
                ))}
              </ul>
            </>
          )}
          {isGlobalAdmin && <FacilitySearch selectFacility={selectFacility} />}
        </div>
      </div>
      <Backdrop open={open} onClick={toggle} />
    </>
  )
}

interface FacilitySelectHeadingProps {
  title: string
}

const FacilitySelectHeading: React.FC<FacilitySelectHeadingProps> = ({ title }) => {
  return <h5 className={styles.facilityTitle}>{title}</h5>
}

type Facility = { Name: string; VenueID: string }

interface FacilityItemProps {
  facility: Facility
  selectFacility: (f: Facility) => void
}

const FacilityItem: React.FC<FacilityItemProps> = ({ facility: f, selectFacility }) => {
  return (
    <li className={styles.facilityItem}>
      <button className={styles.facilityItemButton} onClick={() => selectFacility(f)}>
        {f.Name}
      </button>
    </li>
  )
}

interface FacilitySearchProps {
  selectFacility: (f: Facility) => void
}

const FacilitySearch: React.FC<FacilitySearchProps> = ({ selectFacility }) => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const { facilities, loading } = useFacilitySearch(searchTerm?.trim())
  return (
    <div className={styles.facilitySearch}>
      <FacilitySelectHeading title={'Global admin - section/district lookup'} />
      <TextInput
        variant="outlined"
        placeholder={t('facility search placeholder')}
        size="small"
        spacing={{ margins: { sm: 'top' } }}
        value={searchTerm}
        onChange={f => setSearchTerm(f.target.value)}
        loading={loading}
      />
      {facilities && facilities.length === 0 && (
        <div className={styles.facilityNoResults}>{t('no results')}</div>
      )}
      {facilities && facilities.length > 0 && (
        <ul className={styles.facilityList}>
          {facilities.map(r => {
            return (
              <FacilityItem
                key={r.value}
                facility={{ Name: r.value, VenueID: r.id }}
                selectFacility={f =>
                  selectFacility({ ...f, Name: f.Name.split(' / ')[1] ?? f.Name })
                }
              />
            )
          })}
        </ul>
      )}
    </div>
  )
}

const useFacilitySearch = (term?: string) => {
  const [facilities, setFacilities] = useState<any[]>()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const search = setTimeout(async () => {
      if (term && term.length > 2) {
        setLoading(true)
        setFacilities(await searchSectionDistrict(term))
        setLoading(false)
      } else {
        setFacilities(undefined)
      }
    }, 400)
    return () => clearTimeout(search)
  }, [term, setFacilities, setLoading])
  return { facilities, loading }
}

export default Header
