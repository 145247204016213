import { useTranslation } from 'react-i18next'
import { LIST_OF_STATES } from './list-of-states'
import moment from 'moment'
import {
  MemberStateEnum,
  TransactionSuccessEnum
} from 'src/graphql-types/memberships/globalMembershipTypes'
import { GetMembership_membership_members_data } from 'src/graphql-types/memberships/GetMembership'
import { LabelVariety } from 'src/components/status-label/status-label'
import { GetMember_member } from 'src/graphql-types/memberships/GetMember'
import { GetMembership_membership_members_data as Member } from 'src/graphql-types/memberships/GetMembership'

interface Options {
  label?: string | null
  value?: string | null
}

export const getFirstOptionValue = (options: Options[] | undefined) => {
  if (!options || !options?.length) {
    return ''
  }

  return options[0].value
}

// export const formatDate = (date: Date, format: string) => {
//   const { t } = useTranslation()
//   if (date) return t('formatted date', { date: date, format: format })
//   return 'N/A'
// }

export const formatDate = (date: any, format: string) => {
  if (date) {
    return moment(date).format(format)
  }

  return 'N/A'
}

export const firstCapital = (label: string) => {
  if (!label) return ''
  return (label.charAt(0).toUpperCase() + label.slice(1, label.length).toLowerCase()).replace(
    /[^0-9a-z]/gi,
    ' '
  )
}

export const parseSchoolName = (name: string) =>
  name.replace(/ Men's Team| Womens's Team| Women's Team/gi, '')

// Removes division substring from conference name
export function parseConferenceName(name: string) {
  if (!name) {
    return ''
  }

  const [division, conference] = name.split(' - ')
  return conference
}

export const getAbbrByStateName = (name: string) => {
  const state = LIST_OF_STATES.find(state => {
    return state?.fullName.toUpperCase() === name.toUpperCase()
  })?.value
  return state ? state : ''
}

type Field = 'status' | 'year in school' | 'collegiate division' | 'division'

export const prettifyValues = (field: Field, value: string): string => {
  switch (field) {
    case 'status':
      switch (value) {
        case 'current junior player':
          return 'junior player'
        case 'current college player':
          return 'senior player'
        case 'NA':
          return 'n/a'
        case 'N/A':
          return 'n/a'
        default:
          return ''
      }

    case 'year in school':
      switch (value) {
        case 'hs freshman':
          return 'hs freshman'
        case 'hs sophomore':
          return 'hs sophomore'
        case 'hs junior':
          return 'hs junior'
        case 'hs senior':
          return 'hs senior'
        case 'college freshman':
          return 'hs freshman'
        case 'college sophomore':
          return 'hs sophomore'
        case 'college junior':
          return 'hs junior'
        case 'college senior':
          return 'hs senior'
        case 'NA':
          return 'n/a'
        case 'N/A':
          return 'n/a'
        default:
          return ''
      }

    case 'collegiate division':
      switch (value) {
        case 'DIV_I':
          return 'division I'
        case 'DIV_II':
          return 'division II'
        case 'DIV_III':
          return 'division III'
        case 'NAIA':
          return 'NAIA'
        case 'JUCO':
          return 'JUCO'
        case 'NA':
          return 'n/a'
        case 'N/A':
          return 'n/a'
        case 'division i':
          return 'division I'
        case 'division ii':
          return 'division II'
        case 'division iii':
          return 'division III'
        case 'naia':
          return 'NAIA'
        case 'juco':
          return 'JUCO'
        default:
          return ''
      }

    case 'division':
      switch (value) {
        case 'DIV_I':
          return 'division I'
        case 'DIV_II':
          return 'division II'
        case 'DIV_III':
          return 'division III'
        case 'NAIA':
          return 'naia'
        case 'JUCO':
          return 'juco'
        case 'NA':
          return 'n/a'
      }

    default:
      return ''
  }
}

export function useMemberStateOptions() {
  const { t } = useTranslation()

  return [
    {
      label: t('unknown'),
      value: 'UNKNOWN'
    },
    {
      label: t('active'),
      value: 'ACTIVE'
    },
    {
      label: t('pending'),
      value: 'PENDING'
    },
    {
      label: t('lapsed'),
      value: 'LAPSED'
    },
    {
      label: t('cancelled'),
      value: 'CANCELLED'
    },
    {
      label: t('refunded'),
      value: 'REFUNDED'
    }
  ]
}

export function pretifyDivision(divisionName: string) {
  const { t } = useTranslation()
  switch (divisionName) {
    case 'DIV_I':
      return t('division I')
    case 'DIV_II':
      return t('division II')
    case 'DIV_III':
      return t('division III')
    case 'NAIA':
      return t('naia')
    case 'JUCO':
      return t('juco')
    case 'NA':
      return t('n/a')
    default:
      return divisionName
  }
}

// Sorts conferences in the following order: Div 1, Div 2, Div 3, NAIA, JUCO.
export function sortConfrences(a, z) {
  const JUNIOR_COLLEGE = 'Junior College'

  if (a?.label?.includes(JUNIOR_COLLEGE) !== z?.label?.includes(JUNIOR_COLLEGE)) {
    return a?.label?.includes(JUNIOR_COLLEGE) ? 1 : -1
  }

  return a?.label > z?.label ? 1 : -1
}

type MemberType = GetMembership_membership_members_data | GetMember_member

export const getMemberStatusColor = (status: string): LabelVariety => {
  switch (status) {
    case MemberStateEnum.ACTIVE:
      return 'success'
    case MemberStateEnum.CANCELLED:
      return 'error'
    case MemberStateEnum.PENDING:
      return 'warning'
    case MemberStateEnum.REFUNDED:
      return 'neutral2'
    case MemberStateEnum.LAPSED:
      return 'neutral'
    default:
      return 'neutral'
  }
}

export function getMemberStatus(member: MemberType): MemberStateEnum {
  return member?.state ?? MemberStateEnum.UNKNOWN
}

export const getPayStatusVariety = (status: string): LabelVariety => {
  switch (status) {
    case TransactionSuccessEnum.SUCCESS:
      return 'success'
    case TransactionSuccessEnum.CANCELLED:
      return 'error'
    case TransactionSuccessEnum.PENDING:
      return 'warning'
    case TransactionSuccessEnum.REFUNDED:
      return 'neutral2'
    default:
      return 'neutral'
  }
}

// the API sometimes returns an array with null
// so we need to filter that before we can compare
export const isSinglePurchaseType = (m: MemberType) =>
  (m.organisation?.teamIds || []).filter(Boolean).length < 2
