import React from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../header/header'
import * as styles from './layout.module.less'
import { getClientConfig } from 'src/config/config'
import SidebarNavigation from '../sidebar-navigation/sidebar-navigation'
import { useRouteRestrictions } from 'src/utils/auth'

interface Props {
  nav?: boolean
  noPadding?: boolean
}

const Layout: React.FC<Props> = ({ children, nav = true, noPadding }) => {
  useRouteRestrictions()
  const { t } = useTranslation()
  const footerLogo = getClientConfig().footerLogoFilename

  return (
    <div className={styles.navRoot}>
      {nav && <SidebarNavigation />}
      <div className={nav ? styles.page : styles.pageNoNav}>
        {nav && <Header />}
        <main className={noPadding ? styles.main : styles.mainPadded}>{children}</main>
        <footer className={styles.footer}>
          <span className={styles.copyright}>
            {new Date().getFullYear()} {t(getClientConfig().footerCopyright)}
          </span>
          {t('Powered by')}
          {footerLogo ? (
            <img className={styles.footerLogo} src={require(`src/images/${footerLogo}`).default} />
          ) : (
            ' Clubspark'
          )}
        </footer>
      </div>
    </div>
  )
}

export default Layout
